import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../../Layout/Header";
import AppFooter from "../../Common/AppFooter";
import CleintNames from "../../../Constants/ClientNames";
import { useDispatch } from "react-redux";
import { actionCustomerCoocupationDropDownDetails, actionCustomerOccupationDetails, actionSubmitCustomerOccupationDetails } from "../../../Store/SagaActions/AadharSagaActions";
import RouteNames from "../../../Constants/RouteNames";
import { Text } from "../../Language/Language";
import EmploymentDropdownComp from "../../../Components/SelectKYCProcessCmp/EmployementDetailsComp/EmploymentDropdownComp";
import toast from "react-hot-toast";


const OccupationDropDown = () => {
  const [ocupationList, setOcupationList] = useState([])
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [selectSubOccupation, setSelectSubOccupation] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  // const [bussinessName, setBussinessName] = useState("");

  const dispatch = useDispatch();
  const vcipkey = sessionStorage.getItem("vcipkey");
  const parsedUser = JSON.parse(sessionStorage.getItem("user"));
  const isTransCorp = parsedUser?.issuername == CleintNames.TRANSCORP || false;

  useEffect(() => {
    const model = {
      "reqtype": "1"
    }
    dispatch(actionCustomerCoocupationDropDownDetails({ model: model, callback: getOcuupationListData }));
  }, [])

  const getOcuupationListData = (data) => {
    setOcupationList(data?.data)
  }

  const handleSelectChange = (event) => {
    setSelectedValues([])
    setAdditionalInfo("")
    setSelectSubOccupation([])
    setSelectedIndex(0)
    const selectedValue = event.target.value;
    const selected = ocupationList.find(occupation => occupation.code === selectedValue);
    setSelectedOccupation(selected);
  };

  // Update default "INR" value for T4 type on initial mount
  useEffect(() => {
    if (isTransCorp) {
      const updatedSelectedValues = selectSubOccupation?.data?.map((item, index) => {
        const isCurrencyDropdown = item?.name === "T4";

        // Automatically select the first option for T4 if it's the first dropdown or previous dropdown is valid.
        if (isCurrencyDropdown && (!selectedValues[index]?.code)) {
          if (index === 0 || selectedValues[index - 1]) {
            return item?.value?.[0];
          }
        }

        return selectedValues[index];
      }) || [];

      // Ensure that the selected values don't trigger a continuous loop
      if (JSON.stringify(updatedSelectedValues) !== JSON.stringify(selectedValues)) {
        setSelectedValues(updatedSelectedValues);

        // Determine where to set the selected index to show the next dropdown
        const firstEmptyIndex = updatedSelectedValues.findIndex(item => item === null);
        if (firstEmptyIndex === -1) {
          setSelectedIndex(selectSubOccupation?.data?.length);
        } else {
          setSelectedIndex(firstEmptyIndex);
        }
      }
    }
  }, [selectSubOccupation, isTransCorp]);

  const clearSubsequentValues = (index) => {
    const updatedSelectedValues = selectedValues.slice(0, index + 1);
    setSelectedValues(updatedSelectedValues);
  };

  const handleSelectOccupationChange = (event, index) => {
    const selectedCode = event.target.value;
    const selectedItem = selectSubOccupation?.data?.[index]?.value?.find(
      (item) => item?.code === selectedCode
    );
    let updatedSelectedValues = [...selectedValues];
    if (selectedItem != undefined) {
      updatedSelectedValues[index] = selectedItem;
    }
    if (selectSubOccupation?.data?.[index + 1]?.name === "T4") {
      updatedSelectedValues[index + 1] = selectSubOccupation?.data?.[index + 1]?.value?.[0];
      setSelectedValues(updatedSelectedValues);
      setSelectedIndex(index + 2); // Move to the dropdown after T4
    } else {
      clearSubsequentValues(index);
      setSelectedValues(updatedSelectedValues);

      if (selectedItem !== undefined) {
        setSelectedIndex(index + 1);
      } else {
        // setSelectedIndex(index);
      }
    }
  };

  const handleSelectOccupationChangeLQ = (event, index) => {
    const selectedCode = event.target.value;
    const selectedItem = selectSubOccupation?.data[index]?.value.find((item) => item.code === selectedCode);
    const updatedSelectedValues = [...selectedValues];
    updatedSelectedValues[index] = selectedItem;
    if (selectedItem != undefined) {
      setSelectedValues(updatedSelectedValues);
      setSelectedIndex(index + 1);
    }
  };

  const labelMap = {
    T1: "Business category",
    T2: "Business category",
    T3: "Income range",
    T4: "Income currency"
  };

  const showLabelNames = (name) => {
    return labelMap[name] || "Occupation";
  };

  const handleAdditionalInfoChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.startsWith('0') && inputValue.length > 0) {
      e.preventDefault();
    } else {
      setAdditionalInfo(inputValue);
    }
  };

  let navigate = useNavigate();
  // window.onpopstate = () => {
  //   navigate(null);
  // }

  useEffect(() => {
    if (selectedOccupation?.name?.length > 0) {
      const model = {
        "reqtype": "2",
        "code": selectedOccupation?.code
      }
      dispatch(actionCustomerCoocupationDropDownDetails({ model: model, callback: subOccupationData }));
    }
  }, [selectedOccupation])

  const subOccupationData = (data) => {
    setSelectSubOccupation(data)
  }

  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);


  const { app_env } = dynamicSettingObj;
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;

  const showSalaryRangeOption = app_env == "lq_uat" || (app_env == "lq_prod" && !window.origin.includes("unimoniindia"));

  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false

  const user = sessionStorage.getItem("user");
  const customerVcipDetails = JSON.parse(user);

  const nextStep = () => {
    // if (customerVcipDetails?.kycstatus === "0") {
    //   if(iskinabank){
    //     navigate(RouteNames.CUSTOMER_ID)
    //   }else{
    //     navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER);
    //   } 
    // } else if (customerVcipDetails?.panstatus === "0") {
    //   if (isClientTideAccess) {
    //     navigate(RouteNames.PAN_CAPTURE, { replace: true });
    //   } else {
    //     if(iskinabank){
    //       navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true });
    //     }
    //     navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
    //   }
    // } else if (customerVcipDetails?.videocallstatus === "0") {
    //   navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
    // }
  };

  const handelSubmitNavigation = () => {
    const emptyDropdown = selectSubOccupation?.data?.find((item, index) => !selectedValues[index]?.code);
    if (emptyDropdown) {
      toast.error(`Please select a value for ${showLabelNames(emptyDropdown?.name)}`);
      return;
    }
    const selectedOccupationData = [selectedOccupation]
    const occupationData = selectedOccupationData?.concat(selectedValues)
    const labels = occupationData.map(item => `${item.name}`).join(',');
    const codes = occupationData.map(item => `${item.code}`).join(',');
    const formattedString = `${labels},${additionalInfo}@${codes}`
    const model = {
      vcipkey: vcipkey,
      cusoccpname: formattedString,

    };
    dispatch(actionSubmitCustomerOccupationDetails({ model: model, callback: submitCustmerOcupationDetails }));
  }

  const submitCustmerOcupationDetails = (data) => {
    if (data?.respcode === "200") {
      navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
    }
  }

  // const isDisabled = (selectedValues?.length != selectSubOccupation?.data?.length) || additionalInfo.length == 0;
  const isDisabled =
    (selectedValues?.length !== selectSubOccupation?.data?.length) ||
    (!isTransCorp && additionalInfo?.length === 0)
  return (
    <>
      <Header
        title={
          isTransCorp ? (
            <Text tid="your_business_details" />
            // <div>asdfasdf</div>  
          ) : (
            isCashbook ? <Text tid="start_your_video_KYC" /> : iskinabank ? <Text tid="commence_your_digital_kyc" /> :
              <Text tid="details" />
          )
        }
        isClientTideAccess={isClientTideAccess}
        noarrow="noarrow"
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      {/* <h1>Hemant Suryawanshi</h1> */}
      {/* {isClientTideAccess ? (
        <TideHeader
          navigate={() => navigate(-1)}
          text={"Identity verification"}
        />
      ) : (
        <Header
          title={<Text tid="commence_video" />}
          navigate={() => navigate(-1)}
          hideHeader={false}
        />
      )} */}
      {isTransCorp ? (
        <article className="app-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
              height: "100%",
            }}
          >
            <EmploymentDropdownComp
              ocupationList={ocupationList}
              selectedOccupation={selectedOccupation}
              handleSelectChange={handleSelectChange}
              selectSubOccupation={selectSubOccupation}
              handleSelectOccupationChange={handleSelectOccupationChange}
              selectedIndex={selectedIndex}
              selectedValues={selectedValues}
              additionalInfo={additionalInfo}
              handleAdditionalInfoChange={handleAdditionalInfoChange}
              showOccupationNewUI={isTransCorp}
              setSelectedValues={setSelectedValues}
              showLabelNames={showLabelNames}
            />
            <div className="homepagebutton" style={{
              backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
              paddingBottom: isTransCorp ? "2%" : ""
            }}>
              <AppFooter
                btnName={<Text tid="start" />}
                isClientTideAccess={isClientTideAccess}
                navigate={handelSubmitNavigation}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </article>
      ) : (
        <article className="app-body">
          <EmploymentDropdownComp
            ocupationList={ocupationList}
            selectedOccupation={selectedOccupation}
            handleSelectChange={handleSelectChange}
            selectSubOccupation={selectSubOccupation}
            // handleSelectOccupationChange={handleSelectOccupationChange}
            selectedIndex={selectedIndex}
            selectedValues={selectedValues}
            additionalInfo={additionalInfo}
            handleAdditionalInfoChange={handleAdditionalInfoChange}
            showOccupationNewUI={isTransCorp}
            setSelectedValues={setSelectedValues}
            showLabelNames={showLabelNames}
            handleSelectOccupationChangeLQ={handleSelectOccupationChangeLQ}
          />
          <div className="homepagebutton" style={{
            backgroundColor: "#FFFFFFF2",
            paddingBottom: "3%"
          }}>
            <AppFooter
              btnName={<Text tid="start" />}
              // navigate={nextStep}
              navigate={handelSubmitNavigation}
              isDisabled={isDisabled}
            />
          </div>
        </article>
      )}
    </>
  );
};

export default OccupationDropDown;
