const customeCodesList = {
    // NETWORK SPEED 
    MAX_NET_SPEED: 5,
    MIN_NET_SPEED: 2,
    SEND_CUSTOMER_NET_SPEED_TO_AGENT: "SEND_CUSTOMER_NET_SPEED_TO_AGENT",
    CAPTURE_CUSTOMER_PAN: "CAPTURE_CUSTOMER_PAN",
    CAPTURE_CUSTOMER_PAN_DONE: "CAPTURE_CUSTOMER_PAN_DONE",
    TAB_MINIMISE:"TAB_MINIMISE",
    TAB_VISIBLE:"TAB_VISIBLE",
    
    // STREAMING TYPE
    STREAMING_TYPE_OPENVIDU: "OPENVIDU",
    STREAMING_TYPE_LIVEKIT: "LIVEKIT",
    STREAMING_TYPE_CHIME: "CHIME",
    
    // STREAMING TYPE
    STREAMING_TYPE_OPENVIDU_VALUE: "1",
    STREAMING_TYPE_LIVEKIT_VALUE: "2",
    STREAMING_TYPE_100MS_LIVE_VALUE: "3",

    // EKYC
    EKYC: "EKYC",
    COSMOS_EKYC: "Cosmos_EKYC",

    // headings
    eKycHeading : "E-KYC"
};

const customeCodes = Object.freeze(customeCodesList);

export default customeCodes;
