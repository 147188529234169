import { detect } from "detect-browser";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// import posthog from 'posthog-js'

import HomeCmp from "../../Components/HomeCmp";
import RouteNames from "../../Constants/RouteNames";
// import { actionLocationDetails, actionReqResStatusLoaderSagaAction } from "../../Store/SagaActions/CommonSagaActions";
import {
  actionCreateSlot,
  actionGetCustomerConsent,
  actionGetCustomerDeclaration,
  actionGetVcipDetails,
} from "../../Store/SagaActions/GetVcipDetailsSagaActions";
import AppFooter from "../Common/AppFooter";
// import StatusCodes from "../../Constants/StatusCodes";
import QR from "../../Components/HomeCmp/QR";
import SelectLangugeFAB from "../../Components/SelectLangugeFAB/SelectLangugeFAB";
import TideUserConsentModal from "../../Components/TideComponents/TideUserConsentModal/TideUserConsentModal";
import CleintNames from "../../Constants/ClientNames";
import StatusCodes from "../../Constants/StatusCodes";
import Header from "../../Layout/Header";
import PortalModal from "../../Portals/PortalModal";
import { Text } from "../Language/Language";
import UserConsentModal from "../Modals/UserConsentModal";
import TablePopup from "./TablePopup";
import { storeDynamicURLAction, getCustomerEnvDataSagaAction } from "../../Store/SagaActions/CommonSagaActions";
import HyperDX from '@hyperdx/browser';

import NetworkLayer from "../../Layout/NetworkLayer";
import { isFeatureFlagEnabled } from "../../utils/validations";
import customeCodes from "../../Constants/customeCodes";
const Home = ({ tracker, intiOpenReplay }) => {
  const lang = sessionStorage.getItem("lang");

  const [ip, setIp] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [locationName, setLocationName] = useState("");
  const [customerVcipDetails, setCustomerVcipDetails] = useState({});
  const [isLargeDevice, setIsLargeDevice] = useState(false);
  const [waitingTimeTxt, setWaitingTimeTxt] = useState("");
  const [intervalVal, setIntervalVal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAPIProcessing, setIsAPIProcessing] = useState(false);
  const [ipValid, setIpValid] = useState(false);
  const [slotipData, setslotipData] = useState({});
  const [onGeoDisableBtn, setOnGeoDisableBtn] = useState(false);
  const [putDynamicUserConsent, setPutDynamicUserConsent] = useState("")
  // const [dynamicUserConsent, setDynamicUserConsent] = useState("")

  const browser = detect();

  const data = sessionStorage.getItem('user');
  const accName = JSON.parse(data)?.acccode;


  const checkBrowserSupport = () => {
    if (browser?.name == "firefox" || browser?.name == "Fenix") {
      console.log("navigating to browser check");
      navigate(RouteNames.BROWSER_CHECK);
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  window.onpopstate = () => {
    navigate(null);
  };
  const location = useLocation();
  const pathName = location.pathname;

  const params = useParams();
  // const path = window.location?.href;
  // sessionStorage.setItem("link", path);
  var intervalClearId = null;

  // const isLoading = useSelector(state => state.HomeReducer.apiStatus);
  // const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const vcipDetails = useSelector((state) => state.VcipReducer.vcipDetails);
  const { app_env, enableUserConsent, dynamicUserConsent, skipPanVerifyInCustomer,getBankDetails } = dynamicSettingObj
  const showDynamicUserConsent = dynamicUserConsent === "true" ? true : false
  const { feature_flags } = vcipDetails
  const isConsentEnable = app_env?.includes("uat") ? "true" : enableUserConsent == "true"
  const isEnableUserConsent = isFeatureFlagEnabled("enable-user-consent", app_env, isConsentEnable)
  const skipPanScreens = skipPanVerifyInCustomer === "true" ? true : false
  const isEnableBankDetailsPage = isFeatureFlagEnabled("", "", getBankDetails);

  const isClientAccess = clientName === CleintNames?.VOLOPAY ? true : false;
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
      clientName === CleintNames?.TIDEQA ||
      clientName === CleintNames?.TIDE
      ? true
      : false;
  const isUsfbBank = clientName === CleintNames?.USFB;
  const isusfb = (clientName === CleintNames?.USFB) || (clientName === "USFBSYNTIZEN") ? true : false;
  const isUnimony = clientName === CleintNames?.UNIMONI ? true : false;

  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false;


  useEffect(() => {
    sessionStorage.removeItem("isReloaded");
    sessionStorage.removeItem("isAutoCallEnded");
    sessionStorage.removeItem("agentendcall");
    sessionStorage.removeItem("tokenSessionExpire");
    sessionStorage.removeItem("InitiateVCFQStorage");
    sessionStorage.removeItem("isVideoStreamDisconnected");
    sessionStorage.removeItem("tokenEpireTime");
    sessionStorage.removeItem("dynamicObj");
    sessionStorage.removeItem("100msLiveToken")
    getVcipDetails();
    // removeStoreDynamicURLs();
    // posthog.capture('my event------>', { property: 'value' });
    return () => {
      clearInterval(intervalClearId);
    };
  }, []);

  // TO EMPTY REDUCER DATA FOR DYNAMIC URLS OBJ
  // const removeStoreDynamicURLs = () => {
  //   const data = {
  //     streamingURL: "",
  //     streamingType: ""
  //   }
  //   dispatch(storeDynamicURLAction(data));
  // }

  useEffect(() => {
    if (customerVcipDetails?.respcode === "200" && intiOpenReplay) {
      if (intiOpenReplay) {
        tracker.start({
          userID: customerVcipDetails?.vcipid || 1
        });
      }
    }
  }, [customerVcipDetails, intiOpenReplay])


  const getVcipDetails = () => {
    const refId = params?.id;
    // // const location = window.location.search
    // const que = new URLSearchParams(location.search);
    // // const refId = params?.id;
    // const refId = que.get("d");
    const model = {
      vcipref: refId,
    };
    sessionStorage.setItem("vcipref", refId);
    dispatch(
      actionGetVcipDetails({ model: model, callback: getVcipDetailsData })
    );

  };

  const getVcipDetailsData = (data) => {
    setCustomerVcipDetails(data);
    // tracker.setUserID(data?.vcipid);
    // if (intiOpenReplay) {
    //   // tracker.start({
    //   //   userID: data?.vcipid || 1
    //   // });
    // }
    // if(process.env.REACT_APP_ENV == "tc_prod"){
    //   posthog.identify(data?.vcipid);
    // }

    if (data.respcode == "200") {
      HyperDX.setGlobalAttributes({
        acccode: data?.acccode,
        userName: data?.cust_name || "",
        portal: "CUSTOMER"
      });
      dispatch(getCustomerEnvDataSagaAction({ model: { acccode: data?.acccode || "" }, callback: getCustomerEnvDataResp }));
    }
    getLatLong();
    // getDeviceSize();
    if (window && window?.innerWidth >= 950) {
      setIsLargeDevice(true);
    } else {
      if (
        data?.vcipstatus === StatusCodes.VCIPSTATUS_UNDERREVIEW ||
        data?.vcipstatus === StatusCodes.VCIPSTATUS_COMPLETED ||
        data?.vcipstatus === StatusCodes.VCIPSTATUS_ONHOLDBYAGENT
      ) {
        navigate(RouteNames.KYC_PROCESS_COMPLETED, { replace: true });
      }
    }
  };



  const getCustomerEnvDataResp = (data) => {

    if (data?.respcode === "200") {
      dispatch(storeDynamicURLAction(data));
      // setEnvError(false);

    } else {
      toast.error("Something went wrong, Environment data missing")
      // setEnvError(true);
    }
  }

  // const getDeviceSize = () => {
  //   if (window?.screen && window?.screen.width >= 950) {
  //     setIsLargeDevice(true);
  //   }
  // };

  const getLatLong = () => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition((this.showPosition));
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // alert(position.coords.latitude, position.coords.longitude);
          if (position.coords.latitude && position.coords.longitude) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            const model = {
              lat: position.coords.latitude,
              long: position.coords.longitude,
            };
            // dispatch(
            //   actionLocationDetails({
            //     model: model,
            //     callback: getLocationDetailsData,
            //   })
            // );
          } else {
            // toast(<Text tid="please_enable_location" />);
          }
        },
        (error) => {
          toast(<Text tid="please_enable_location" />);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    } else {
      toast.warn(<Text tid="geolocation_is_not_supported_by_this_browser" />);
    }
    // (async () => {
    //   console.log("calling v4 api");
    //   dispatch(actionReqResStatusLoaderSagaAction(true));
    //   try{
    //     // const ip = await publicIp.v4();
    //     // setIp(ip);
    //     dispatch(actionReqResStatusLoaderSagaAction(false));
    //     console.log("loader false");

    //   }catch(error){
    //     console.log("Error fetching location ip v4 data:", error);
    //     dispatch(actionReqResStatusLoaderSagaAction(false));
    //     console.log("loader false");
    //   }
    // })();
  };

  // const getLocationDetailsData = (data) => {
  //   console.log("llllllllllllll geo loc", data);
  //   setLocationName(data);
  // };
  const handlePermissionChange = () => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      // console.log(result,"======result");
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position.coords.latitude, position.coords.longitude, "land and latt");
          setLong(position.coords.longitude);
          setLat(position.coords.latitude);
        });
      }
    })
  }

  useEffect(() => {
    let interval;
    if (!lat && !long) {
      interval = setInterval(handlePermissionChange, 1000);
    } else {
      clearInterval(interval); // Cleanup: Clear the interval when component unmounts
    }

    return () => {
      clearInterval(interval); // Cleanup: Clear the interval when component unmounts
    };
  }, [lat, long]);

  const startProcess = () => {
    // condition to check if its USFB bank and check firefox browser
    if (isUsfbBank) {
      checkBrowserSupport();
    }
    // if (lat && long) {
    setIsAPIProcessing(true);
    const vcipkey = sessionStorage.getItem("vcipkey");
    const model = {
      vcipkey: vcipkey,
      custdetails: {
        // ip,
        location: lat + "," + long,
        // location: ',', 
        // geolocation: locationName,
        nw_incoming: "",
        nw_outgoing: "",
        videoresolution: "",
      },
      ref1: "",
    };
    console.log("location details ,", lat, long);
    dispatch(
      actionCreateSlot({
        model: model,
        callback: getCreateSlotData
      })
    );
    // } else {
    //   toast(
    //     "Unable to find your location. Please check your location and try again."
    //   );
    // }
  };

  if (pathName == "/") {
    sessionStorage.clear();
    const inter = setInterval(async () => {
      await window.location.reload();
    }, 1000);
    clearInterval(inter);
  }

  const validateIPdata = (data) => {
    let valid = false;
    Object.keys(data)?.forEach((item, i) => {
      if (
        data[item] === true &&
        (item == "is_tor" ||
          item == "is_proxy" ||
          item == "is_datacenter" && isUnimony == false ||
          item == "is_icloud_relay")
      ) {
        if (data[item] === true) {
          valid = true;
        }
      }
    });
    return valid;
  };

  const dynamicUserConsentFunc = () => {
    const model = {
      "reqtype": "1"
    }
    dispatch(actionGetCustomerDeclaration({
      model: model,
      callback: getCustomerDeclarationData
    }))
  }

  const getCreateSlotData = (data) => {
    if (data?.respcode === "200") {
      const ipdata = JSON.parse(data?.ipdata);
      let valid;
      if (Object.values(ipdata).length > 0) {
        valid = validateIPdata(ipdata);
      }
      setIpValid(valid);
      setslotipData(ipdata);
      sessionStorage.setItem(
        "location",
        lat ? lat + "," + long : data?.geolatlon
      );
      sessionStorage.setItem("geolocation", data?.geoloc);
      clearInterval(intervalClearId);
      setWaitingTimeTxt("");
      // navigate(RouteNames.CHOOSE_LANGAUGE);
      // if (
      //   customerVcipDetails?.kycstatus === "0" ||
      //   customerVcipDetails?.panstatus === "0"
      // ) {
      if (showDynamicUserConsent) {
        dynamicUserConsentFunc()
      } else{
        openModal();
      } 
      // } else {
      //   if (showDynamicUserConsent) {
      //     dynamicUserConsentFunc()
      //   } else{
      //     openModal();
      //   } 
      //   // startProcess1();
      //   // navigate(RouteNames.CHOOSE_LANGAUGE);
      // }
    } else {
      // setWaitingTimeTxt(data?.respdesc);
      if (data?.respcode === "489") {
        if (data?.respdesc?.includes("to continue", "seconds")) {
          let sen = data?.respdesc;
          let number;
          let timerreduce;
          let pat = /\d+/; // this matches any sequence of digits
          const match = sen.match(pat);
          if (match) {
            number = parseInt(match[0]); // convert the matched string to a number
          }
          const createslottimer = setInterval(() => {
            if (number == 0) {
              clearInterval(createslottimer);
              setTimeout(() => {
                startProcess();
              }, 1000)
            } else {
              timerreduce = (parseInt(number) - 1);
              number = timerreduce;
              setWaitingTimeTxt(
                ` Please try after ${timerreduce} seconds to continue the VCIP session.`
              );
            }
          }, 1000);
          // intervalClearId = setInterval(() => {
          //   startProcess();
          // }, 2000);
        } else {
          setWaitingTimeTxt(data?.respdesc);
        }

      }
      if (data?.respcode === "600") {
        // toast.error(data?.respdesc)
        setOnGeoDisableBtn(true);
        setTimeout(() => {
          setOnGeoDisableBtn(false);
        }, 3000);

      }
    }
    setIsAPIProcessing(false);
  };


  const getCustomerDeclarationData = (data) => {
    if (data?.respcode === "200") {
      openModal()
      setPutDynamicUserConsent(data?.Declaration)
    }else{
      setIsOpen(false)
    }
  }

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  const startProcess1 = () => {
    const model = {
      vcipkey: sessionStorage.getItem("vcipkey"),
      custconsent: "I provide my consent to all the points mentioned here"
    }
    isEnableUserConsent && dispatch(actionGetCustomerConsent({ model: model }))
    if (customerVcipDetails?.kycstatus === "0") {
      if (customerVcipDetails?.panstatus === "1") {
        if (feature_flags?.kyctype === customeCodes?.EKYC || feature_flags?.kyctype === customeCodes?.COSMOS_EKYC) {
          navigate(RouteNames.AADHAR_KYC_PROCESS_UPLOAD);
        } else {
          navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER);
        }
      } else {
        navigate(RouteNames.SELECT_KYC_PROCESS);
      }
    } else if (customerVcipDetails?.panstatus === "0" && !skipPanScreens) {
      if (isClientTideAccess) {
        navigate(RouteNames.PAN_CAPTURE, { replace: true });
      } else {
        if (iskinabank) {
          navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true });
        } else {
          navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
        }
      }
    } else if (customerVcipDetails?.videocallstatus === "0") {
      //need to add bank account empty condtion navigation 
      if(isEnableBankDetailsPage && (customerVcipDetails?.accverification ?  customerVcipDetails?.accverification == "0" : true)){ 
        navigate(RouteNames.BANK_DETAILS);
      }else if (customerVcipDetails?.custoccupname == "" && !isusfb) {
        navigate(RouteNames.EMPLOYEMENT_DETAILS);
      } else {
        navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
      }
    }
  };

  // skipPanVerifyInCustomer --> True ? and panstatus 0 then skip pan screens
  // skipPanVerifyInCustomer --> True ? and panstatus 1 then skip pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 0 show pan screens
  // skipPanVerifyInCustomer --> false ? and panstatus 1 then skip pan screens



  const isDisabled =
    Object.values(customerVcipDetails)?.length > 0 &&
      customerVcipDetails?.vcipstatus !== StatusCodes.VCIPSTATUS_UNDERREVIEW &&
      customerVcipDetails?.vcipstatus !== StatusCodes.VCIPSTATUS_ONHOLDBYAGENT &&
      customerVcipDetails?.vcipstatus !== StatusCodes.VCIPSTATUS_COMPLETED
      ? false
      : true;

  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;

  const vcipid = customerVcipDetails?.vcipid;
  const cust_name = customerVcipDetails?.cust_name;

  const vcipLinkURL = window.location?.href;
  sessionStorage.setItem("link", vcipLinkURL);

  const url = () => {
    url = url?.split("=");
    navigate("/d=" + url[1], { replace: true });
  };

  return (
    <>
      {customerVcipDetails?.respcode == "406" && (
        <div className="nodata">
          <div className="text1">
            <Text tid="invalid_url" />
          </div>
          <div className="text2">
            <Text tid="sorry_the_page_not_to_found" />
          </div>
          <Link to={url} className="link">
            {vcipLinkURL}
          </Link>
        </div>
      )}

      {isCashbook && !isOpen && <SelectLangugeFAB />}
      {isLargeDevice && !isClientAccess ? (
        <QR qrimage={customerVcipDetails?.qrimage} vcipLinkURL={vcipLinkURL} />
      ) : (
        <>
          {!isClientTideAccess && (
            <Header
              title={""}
              navigate={() => navigate(-1)}
              hideHeader={false}
            />
          )}
          {clientName && customerVcipDetails?.respcode != "406" ? (
            <article className="app-body">
              <HomeCmp
                isLargeDevice={isLargeDevice && !isClientAccess}
                customerVcipDetails={customerVcipDetails}
                isClientTideAccess={isClientTideAccess}
                statusTxt={
                  customerVcipDetails?.vcipstatus ===
                  StatusCodes.VCIPSTATUS_UNDERREVIEW ||
                  customerVcipDetails?.vcipstatus ===
                  StatusCodes.VCIPSTATUS_COMPLETED ||
                  customerVcipDetails?.vcipstatus ===
                  StatusCodes.VCIPSTATUS_ONHOLDBYAGENT
                }
                waitingTimeTxt={waitingTimeTxt}
              />
              <div
                className={"homepagebutton"}
                style={{
                  paddingBottom: isClientTideAccess ? "4%" : "3%",
                  backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
                }}
              >
                {(isClientAccess || !isLargeDevice) &&
                  !waitingTimeTxt &&
                  customerVcipDetails?.respcode != "406" && (
                    <AppFooter
                      btnName={
                        isClientTideAccess ? (
                          <Text tid="continue_button" />
                        ) : (
                          <Text tid="Get_Started" />
                        )
                      }
                      // btnName={<Text tid="start" />}
                      isDisabled={isDisabled || isAPIProcessing || onGeoDisableBtn}
                      navigate={startProcess}
                      isClientTideAccess={isClientTideAccess}
                    />
                  )}
              </div>
            </article>
          ) : null}
        </>
      )}

      {ipValid ? (
        <PortalModal isOpen={isOpen}>
          <TablePopup ipdata={slotipData} closeModal={closeModal} isUnimony={isUnimony} />
        </PortalModal>
      ) : (isClientTideAccess || isCashbook) && isOpen ? (
        <TideUserConsentModal
          agree={startProcess1}
          vcipid={vcipid}
          cust_name={cust_name}
          closeModal={closeModal}
          isClientTideAccess={isClientTideAccess}
          isCashbook={isCashbook}
        />
      ) : (
        <PortalModal isOpen={isOpen}>
          <UserConsentModal
            agree={startProcess1}
            userConsent={putDynamicUserConsent}
            vcipid={vcipid}
            cust_name={cust_name}
            closeModal={closeModal}
            iskinabank={iskinabank}
            isusfb={isusfb}
          />
        </PortalModal>
      )}
    </>
  );
};

export default Home;
