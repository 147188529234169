const ClientNamesList = {
    VOLOPAY: 'VOLOPAY',
    TIDEDEV: 'TIDEDEV',
    TIDEQA: 'TIDEQA',
    TIDE: 'TIDE',
    LOGIPE: 'LOGIPE',
    BRANCHX:'BRANCHX',
    CASHBOOK:"CASHBOOK",
    KINABANK:"KINA",
    IPPB: "IPPB",
    TRANSCORP: "TRANSCORP",
    USFB: "USFB",
    UNIMONI: "UNIMONI",
    AKUDO: "AKUDO",
    USFBSYNTIZEN: "USFBSYNTIZEN"
}

const CleintNames = Object.freeze(ClientNamesList);

export default CleintNames;
